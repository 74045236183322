import React from "react";

import "./index.css";

class NoMatchPage extends React.Component {
  render() {
    return (
      <div className="NoMatchPageContainer">
        <div className="NoMatchPage">
          <h3>404 - Not found</h3>;
        </div>
      </div>
    );
  }
}

export default NoMatchPage;
